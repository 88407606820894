import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaMapMarkerAlt, FaRoute, FaShieldAlt } from 'react-icons/fa';

const Section = styled.section`
  padding: 160px 24px;
  background: #000;
  color: #fff;
  overflow: hidden;
  position: relative;

  &::before {
    // content: "PARKINGKARMA";
    position: absolute;
    font-size: 200px;
    font-weight: 900;
    opacity: 0.03;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    pointer-events: none;
  }
`;

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`;

const SectionHeader = styled.div`
  text-align: left;
  margin-bottom: 120px;
  padding-left: 40px;
  border-left: 4px solid #FF5A5A;
`;

const Overline = styled.span`
  color: #FF5A5A;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 8px;
  display: block;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-size: clamp(3rem, 8vw, 5rem);
  font-weight: 900;
  color: #fff;
  margin-bottom: 24px;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -1px;
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.7);
  max-width: 600px;
  line-height: 1.6;
  font-weight: 300;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 40px;
  margin-top: 60px;
`;

const FeatureCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  padding: 40px;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent, rgba(255, 90, 90, 0.03));
    z-index: 0;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.05);
    transform: translateY(-5px);
  }
`;

const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  background: #FF5A5A;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  color: white;
  font-size: 32px;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 100%;
    height: 100%;
    background: rgba(255, 90, 90, 0.3);
    z-index: -1;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 1.75rem;
  color: #fff;
  margin-bottom: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  z-index: 1;
`;

const FeatureDescription = styled.p`
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  position: relative;
  z-index: 1;
`;

const LocationWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
  z-index: 1;
`;

const LocationTag = styled.span`
  display: inline-block;
  background: rgba(255, 90, 90, 0.1);
  border: 1px solid #FF5A5A;
  color: #FF5A5A;
  padding: 8px 16px;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 100%;
    height: 100%;
    background: rgba(255, 90, 90, 0.1);
    z-index: -1;
  }
`;

const AboutUs = () => {
  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  return (
    <Section id="about">
      <Container>
        <SectionHeader>
          <Overline>About ParkingKarma</Overline>
          <Title>Instant Parking for San Francisco</Title>
          <Subtitle>
            We're revolutionizing street parking in SF with real-time availability,
            enforcement alerts, and turn-by-turn navigation.
          </Subtitle>
        </SectionHeader>

        <FeaturesGrid>
          <FeatureCard
            variants={cardVariants}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <IconWrapper>
              <FaMapMarkerAlt />
            </IconWrapper>
            <FeatureTitle>Real-Time Parking Spots</FeatureTitle>
            <FeatureDescription>
              Find available street parking instantly with our real-time detection system.
              <LocationWrapper>
                <LocationTag>North Beach</LocationTag>
                <LocationTag>SOMA</LocationTag>
                <LocationTag>Marina</LocationTag>
                <LocationTag>Hayes Valley (Coming Soon)</LocationTag>
              </LocationWrapper>
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard
            variants={cardVariants}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <IconWrapper>
              <FaShieldAlt />
            </IconWrapper>
            <FeatureTitle>Avoid Parking Tickets</FeatureTitle>
            <FeatureDescription>
              Stay informed with our live map of parking enforcement officer locations.
              Know where they are and avoid expensive tickets.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard
            variants={cardVariants}
            initial="hidden"
            whileInView="visible"
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <IconWrapper>
              <FaRoute />
            </IconWrapper>
            <FeatureTitle>Turn-by-Turn Navigation</FeatureTitle>
            <FeatureDescription>
              Get guided directly to available parking spots with our precise
              navigation system. No more circling the block.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
      </Container>
    </Section>
  );
};

export default AboutUs;
