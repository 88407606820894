import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaEnvelope } from 'react-icons/fa';
import { FooterContainer, FooterWrap, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIconLink } from './FooterElements';
// import techstarsLogo from "../../images/tschijpm.svg";
import techstarsLogo from "../../images/techstars.png";
import boostVcLogo from "../../images/boostlogo.png";
import styled from 'styled-components';

const BackedBySection = styled.div`
  background: #0A0A0A;
  padding: 60px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const BackedByContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const BackedByTitle = styled.h3`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 40px;
  font-weight: 600;
  opacity: 0.9;
`;

const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
`;

const LogoWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  
  &:hover {
    opacity: 0.8;
  }
`;

const Logo = styled.img`
  height: 150px;
  // filter: brightness(0) invert(1);
  
  @media (max-width: 768px) {
    height: 100px;
  }
`;

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <BackedBySection>
        <BackedByContainer>
          <BackedByTitle>Backed by</BackedByTitle>
          <LogosContainer>
            <LogoWrapper
              href="https://www.techstars.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo src={techstarsLogo} alt="Techstars powered by JP Morgan" />
            </LogoWrapper>
            <LogoWrapper
              href="https://www.boost.vc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo src={boostVcLogo} alt="Boost VC" />
            </LogoWrapper>
          </LogosContainer>
        </BackedByContainer>
      </BackedBySection>

      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              ParkingKarma
            </SocialLogo>
            <WebsiteRights>
              ParkingKarma © {new Date().getFullYear()} All rights reserved.
            </WebsiteRights>
            <SocialIconLink
              href="mailto:drake@parkingkarma.io"
              target="_blank"
              aria-label="Email"
            >
              <FaEnvelope />
            </SocialIconLink>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
