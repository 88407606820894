import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import directionsImage from "../../images/directions.svg";
import destinationImage from "../../images/destination.svg";
import parkingImage from "../../images/parking.svg";

const Section = styled.section`
  padding: 120px 24px;
  background: #000;
  color: #fff;
  position: relative;
  overflow: hidden;

  &::before {
    // content: "HOW IT WORKS";
    position: absolute;
    font-size: 200px;
    font-weight: 900;
    opacity: 0.03;
    top: 0;
    left: -20px;
    white-space: nowrap;
    pointer-events: none;
  }
`;

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.div`
  text-align: left;
  margin-bottom: 80px;
  padding-left: 40px;
  border-left: 4px solid #FF5A5A;
`;

const Title = styled.h2`
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 900;
  margin-bottom: 24px;
  text-transform: uppercase;
  letter-spacing: -1px;
  line-height: 1;
`;

const StepsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  align-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const StepContent = styled(motion.div)`
  padding: 40px;
`;

const StepNumber = styled.span`
  font-size: 1rem;
  color: #FF5A5A;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 16px;
  display: block;
`;

const StepTitle = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 24px;
  line-height: 1.2;
`;

const StepDescription = styled.p`
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.6;
  margin-bottom: 32px;
`;

const PhoneFrame = styled(motion.div)`
  // background: #1a1a1a;
  border-radius: 48px;
  padding: 16px;
  position: relative;
  max-width: 375px;
  margin: 0 auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 24px;
    // background: #000;
    border-radius: 0 0 16px 16px;
  }
`;

const Screenshot = styled.img`
  width: 100%;
  border-radius: 32px;
  display: block;
`;


const VideoSection = styled.div`
  margin-bottom: 120px;
  text-align: center;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  
  &::before {
    content: '';
    display: block;
    padding-top: 177.77%;
  }
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const VideoTitle = styled.h2`
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 900;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: -1px;
  line-height: 1.1;
  
  span {
    color: #FF5A5A;
  }
`;

const Services = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.3 } }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  return (
    <Section>
      <Container>
        <Header>
          <Title>Experience Seamless Parking</Title>
        </Header>

        <VideoSection>
          <VideoTitle>
            See <span>Real-Time</span> Parking<br />in Action
          </VideoTitle>
          <VideoWrapper>
            <iframe
              src="https://www.youtube.com/embed/6270tbe7a34"
              title="ParkingKarma Demo"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoWrapper>
        </VideoSection>

        <StepsGrid>
          <StepContent
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
          >
            <motion.div variants={itemVariants}>
              <StepNumber>Step 01</StepNumber>
              <StepTitle>Search Your Destination</StepTitle>
              <StepDescription>
                Enter where you're headed and instantly see available parking spots nearby.
                Our real-time map shows you exactly where to find open spaces.
              </StepDescription>
            </motion.div>
          </StepContent>

          <PhoneFrame
            variants={itemVariants}
            initial="hidden"
            whileInView="visible"
          >
            <Screenshot src={destinationImage} alt="Search Destination" />
          </PhoneFrame>



          <StepContent
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
          >
            <motion.div variants={itemVariants}>
              <StepNumber>Step 02</StepNumber>
              <StepTitle>Choose Your Spot</StepTitle>
              <StepDescription>
                Select from available spots and see key details like parking restrictions
                and enforcement activity in the area.
              </StepDescription>
            </motion.div>
          </StepContent>

          <PhoneFrame
            variants={itemVariants}
            initial="hidden"
            whileInView="visible"
          >
            <Screenshot src={parkingImage} alt="Select Parking" />
          </PhoneFrame>

          <StepContent
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
          >
            <motion.div variants={itemVariants}>
              <StepNumber>Step 03</StepNumber>
              <StepTitle>Navigate & Park</StepTitle>
              <StepDescription>
                Get turn-by-turn directions straight to your chosen spot.
                Avoid parking tickets with real-time enforcement alerts.
              </StepDescription>
            </motion.div>
          </StepContent>

          <PhoneFrame
            variants={itemVariants}
            initial="hidden"
            whileInView="visible"
          >
            <Screenshot src={directionsImage} alt="Navigation" />
          </PhoneFrame>
        </StepsGrid>
      </Container>
    </Section>
  );
};

export default Services;
