import React, { useState } from 'react';
// Remove Sidebar import if you're not using it
// import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import Footer from '../components/Footer';
import StopWasting from "./../components/StopWastingTime"
import SecondPage from '../components/AboutUs';
// import InfoSection from '../components/InfoSection';
// import { homeObjTwo, homeObjThree } from '../components/InfoSection/Data';
// import JoinSection from '../components/Join';
// import AISection from '../components/AISection';
// import { homeObjOne } from '../components/AISection/Data';
import Screenshots from "./../components/Screenshots/screenshots"

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    };

    return (
        <>
            {/* If you're not using Sidebar, remove this */}
            {/* <Sidebar isOpen={isOpen} toggle={toggle} /> */}
            <Navbar toggle={toggle} />
            <HeroSection />
            <Screenshots />

            <Services />
            <SecondPage />
            <StopWasting />

            {/* <InfoSection {...homeObjTwo} /> */}
            {/* <AISection {...homeObjOne} /> */}
            {/* <JoinSection {...homeObjThree} /> */}
            {/* <FuckingParking /> */}
            <Footer />
        </>
    );
};

export default Home;
