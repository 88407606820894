import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

// Styled component for the main container
const Container = styled.div`
  text-align: center;
  background-color: #000;
  padding: 60px 0;
  min-height: 20vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

// Styled component for the heading
const Heading = styled.h1`
  font-size: 42px;
  margin-bottom: 20px;
`;

// Styled component for the additional message
const AdditionalMessage = styled.h1`
  font-size: 28px;
  margin-bottom: 40px;
`;

// Matching the Hero section's button styling
const DownloadButton = styled.a`
    background: linear-gradient(135deg, #FF5A5A 0%, #FF8080 100%);
    padding: 1.2rem 2.4rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(255, 90, 90, 0.3);
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    margin-top: 20px;
    
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 20px rgba(255, 90, 90, 0.4);
        background: linear-gradient(135deg, #FF4040 0%, #FF6060 100%);
    }

    span {
        font-size: 1.4rem;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 20px;
`;

function App() {
  const options = useMemo(() => [
    'dinner reservation',
    "home after work",
    'catfish Tinder date',
    'BBL appointment',
    'tattoo removal appointment',
    'terrible tanning appointment',
    'gym you barely go to',
    'job that you hate',
    'job that you love',
    'second set of wife and kids',
    'in laws that you hate',
    "therapist that doesn't listen",
    'breakup lunch',
    "hair salon appoinment",
    'sneaky link',
    'lunch reservation',
    'divorce settlement',
    'church you only go to once a quarter',
    "kid's boring practice",
    'custody hearing',
    'VC meeting',
    "first hinge date",
    "doctor's appoinment",
    "cult meeting",
    'botox injection',
    "overpriced grocery store",
    "anniversary date",
    "friend's birthday party",
    "movie date",
    "apartment showing",
    "loveless toxic relationship"
  ], []);

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % options.length);
    }, 3500);

    return () => clearInterval(interval);
  }, [options.length]);

  useEffect(() => {
    setSelectedOption(options[index]);
  }, [index, options]);

  return (
    <Container>
      <Heading>Stop wasting time looking for parking.</Heading>
      <AdditionalMessage>
        Get to your <span style={{ color: '#FF5A5A', fontWeight: 'bold' }}>{selectedOption}</span> faster.
      </AdditionalMessage>
      <ButtonWrapper>
        <DownloadButton
          href="https://forms.gle/NUTsZhjJ8BoSaZzy7"
          target="_blank"
        >

          Download the app
        </DownloadButton>
      </ButtonWrapper>
    </Container>
  );
}

export default App;