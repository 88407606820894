import styled from 'styled-components';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index: 1;
    overflow: hidden;
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.9) 0%,
            rgba(0, 0, 0, 0.7) 50%,
            rgba(0, 0, 0, 0.9) 100%
        );
        z-index: 2;
    }
    ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            180deg,
            rgba(0,0,0,0.7) 0%,
            rgba(0,0,0,0.85) 100%
        );
        z-index: 2;
    }
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: rgba(0, 0, 0, 0.85);
    z-index: 1;
    filter: brightness(0.4);
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
`;

export const HeroH1 = styled.h1`
    color: #fff;
    font-size: clamp(48px, 8vw, 84px);
    text-align: center;
    font-weight: 800;
    letter-spacing: -1.5px;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 1rem;
    
    span {
        display: inline-block;
        opacity: 0.9;
    }

    @media screen and (max-width: 1024px) {
        font-size: 72px;
    }

    @media screen and (max-width: 768px) {
        font-size: 56px;
        letter-spacing: -1px;
    }

    @media screen and (max-width: 480px) {
        font-size: 36px;
        letter-spacing: -0.5px;
    }
`;

export const HeroH2 = styled.h2`
    color: #fff;
    font-size: 50px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 60px;
    }

    @media screen and (max-width: 480px) {
        font-size:40px;
    }
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 42px;
    text-align: center;
    max-width: 800px;
    font-weight: 500;
    letter-spacing: -0.5px;
    opacity: 0.9;

    @media screen and (max-width: 768px) {
        font-size: 30px;
        margin-top: 0px;


    }

    @media screen and (max-width: 480px) {
        font-size: 26px;
        margin-top: 0px;

    }
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;

